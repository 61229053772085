import React, { useState, useEffect } from 'react';
import { sendMacroMessage, downloadHistory, copyToClipboard, handleSendMessage } from '../../components/chatbot.js'; // Ruta ajustada
import { getUserId, getSessionId, generateSessionId, generateUserId } from '../../components/storage.js'; // Ruta ajustada
import { fetchConversations, fetchMessagesBySession } from '../../components/history.js'; // Ruta ajustada
import ReactMarkdown from 'react-markdown';
import convertTableTextToHtml from '../../components/tableConverter.js';
import { handleKeyDown, useAutoResizeTextArea } from '../../components/textAreautils.js'; // Ruta ajustada
import { v4 as uuidv4 } from 'uuid';  // Importar uuid
import { classifyMessage } from '../../components/votemessage.js';

// Definimos los mensajes predeterminados
const presetMessages = [
    "¿Cuál es el CTR obtenido en las campañas de awareness de Onboarding?",
    "¿Cual es la campaña con mayor inversión en google?",
    "¿Cuál es la inversión de las campañas de conversiones en TC?",
    "¿Cuál es el total de compras realizadas en todas las campañas de Meta?",
    "Cual es el CPC en las campañas de consideración de Préstamos?",
    "¿Cuál fue la inversión de las campañas Banco Chat?",
    "¿Cuántas conversiones obtuvimos en las campañas de conversión de Onboarding?"
];


const PresetMessagesScrollable = ({ presetMessages, onSelectMessage }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const itemsPerView = 4;

    const handleScroll = (direction) => {
        const newPosition = direction === 'right' 
            ? Math.min(scrollPosition + 1, presetMessages.length - itemsPerView)
            : Math.max(scrollPosition - 1, 0);
        setScrollPosition(newPosition);
    };

    const visibleMessages = presetMessages.slice(scrollPosition, scrollPosition + itemsPerView);

    return (
        <div className="preset-messages-container">
            <div className="preset-messages-wrapper">
                {scrollPosition > 0 && (
                    <button 
                        onClick={() => handleScroll('left')}
                        className="scroll-button scroll-left"
                    >
                        <i className="fas fa-chevron-left"></i>
                    </button>
                )}

                <div className="preset-messages-viewport">
                    <div className="preset-messages-track">
                        {visibleMessages.map((preset, index) => (
                            <button
                                key={index}
                                onClick={() => onSelectMessage(preset)}
                                className="preset-message-button"
                            >
                                {preset}
                            </button>
                        ))}
                    </div>
                </div>

                {scrollPosition < presetMessages.length - itemsPerView && (
                    <button 
                        onClick={() => handleScroll('right')}
                        className="scroll-button scroll-right"
                    >
                        <i className="fas fa-chevron-right"></i>
                    </button>
                )}
            </div>
        </div>
    );
};

export const ChatbotMacro = () => {
    const [userId, setUserId] = useState(getUserId());
    const [sessionId, setSessionId] = useState(getSessionId());
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [sessions, setSessions] = useState({});
    const [sidebarOpen, setSidebarOpen] = useState(false); // Estado para controlar si la sidebar está abierta o cerrada
    const [isLoading, setIsLoading] = useState(false);
    const cliente = 'macro';
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dateRangeOpen, setDateRangeOpen] = useState(false);
    const [currentSelection, setCurrentSelection] = useState('Mes actual');



    useEffect(() => {
        if (!userId) {
            generateUserId().then((newUserId) => {
                setUserId(newUserId);
            });
        } else {
            fetchConversations(userId, cliente).then(data => {
                const orderedSessions = organizeSessionsByDate(data);
                setSessions(orderedSessions);
            });
        }

        const newSessionId = generateSessionId();
        setSessionId(newSessionId);
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        setStartDate(firstDayOfMonth.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        sendInitialRequest(newSessionId);

    }, [userId]);

    const sendInitialRequest = async (sessionId) => {
        setIsLoading(true);
        const timeWindow = `${startDate} to ${endDate}`;
        const response = await sendMacroMessage("", sessionId, timeWindow);
        if (response.response) {
            displayMessage(response.response, 'bot');
        }
        setIsLoading(false);
    };

    const organizeSessionsByDate = (sessions) => {
        const today = new Date();
        const oneDay = 24 * 60 * 60 * 1000;
        const sevenDays = 7 * oneDay;
        const thirtyDays = 30 * oneDay;
    
        const orderedSessions = {
            today: [],
            last7Days: [],
            last30Days: [],
        };
    
        for (const sessionId in sessions) {
            const sessionMessages = sessions[sessionId];
            
            // El último mensaje ahora es el índice 0
            const lastMessage = sessionMessages[0];
            
            // Ahora buscamos el penúltimo mensaje (índice 1) primero
            const maxIndex = sessionMessages.length - 1; // Obtiene el índice máximo
            const displayQuestion = sessionMessages[maxIndex - 1]?.question || sessionMessages[maxIndex]?.question || 'Sin pregunta';
            
            const lastMessageDate = new Date(lastMessage.timestamp);
            const diff = today - lastMessageDate;
    
            const sessionDisplay = {
                sessionId: sessionId,
                displayText: displayQuestion,
                lastMessageDate
            };
    
            if (diff < oneDay) {
                orderedSessions.today.push(sessionDisplay);
            } else if (diff < sevenDays) {
                orderedSessions.last7Days.push(sessionDisplay);
            } else if (diff < thirtyDays) {
                orderedSessions.last30Days.push(sessionDisplay);
            }
        }
    
        // Ordenar las sesiones dentro de cada categoría por fecha
        for (const category in orderedSessions) {
            orderedSessions[category].sort((a, b) => b.lastMessageDate - a.lastMessageDate);
        }
    
        return orderedSessions;
    };

    // Update the render section for the sidebar
    const renderSessionList = (sessionList, title) => {
        return (
            <>
                <h4>{title}</h4>
                <ul>
                    {sessionList && sessionList.length > 0 ? (
                        sessionList.map(session => (
                            <li
                                key={session.sessionId}
                                onClick={() => handleSessionSelect(session.sessionId)}
                                style={{ cursor: 'pointer', padding: '5px', border: '1px solid #444' }}
                            >
                                {session.displayText}
                            </li>
                        ))
                    ) : (
                        <li>No sessions available</li>
                    )}
                </ul>
            </>
        );
    };

    const handleSessionSelect = async (sessionId) => {
        setSessionId(sessionId);
        const sessionMessages = await fetchMessagesBySession(userId, sessionId, cliente);
        sessionMessages.reverse();
        
        const formattedMessages = [];
        sessionMessages.forEach((msg) => {
            const questionText = typeof msg.question === 'string' ? msg.question : '';
            const responseText = typeof msg.response === 'string' ? msg.response : '';
    
            formattedMessages.push({
                id: uuidv4(),
                text: questionText,
                sender: 'user'
            });
    
            const isImage = responseText.startsWith('https://storage.googleapis.com/usuarios-datalake/graficos');
            formattedMessages.push({
                id: uuidv4(),
                text: isImage ? null : responseText,
                imageUrl: isImage ? responseText : null,
                sender: 'bot'
            });
        });
    
        setMessages(formattedMessages);
    };

    const displayMessage = (message, sender) => {
        let text = null;
        let imageUrl = null;

        if (typeof message === 'object' && message !== null) {
            if ('text' in message) {
                text = message.text;
            }
            if ('imageUrl' in message) {
                imageUrl = message.imageUrl;
            }
        } else if (typeof message === 'string') {
            text = message;
        } else {
            console.error('Expected message to be a string or an object, but got:', message);
            return;
        }

        if (!text && !imageUrl) {
            console.error('Message object is missing both text and imageUrl:', message);
            return;
        }

        setMessages(prevMessages => [
            ...prevMessages,
            { id: uuidv4(), text, imageUrl, sender }
        ]);
    };

    const handleSendMessageWrapper = async () => {
        setIsLoading(true); // Inicia el indicador de carga
        setMessage('');  // Limpiar el campo después de enviar el mensaje
        const timeWindow = `${startDate} to ${endDate}`;
        await handleSendMessage(message, sessionId, displayMessage, (msg, sesId) => sendMacroMessage (msg, sesId, timeWindow));
        setIsLoading(false); // Termina el indicador de carga
        
    };

    const handleDownloadHistory = () => {
        downloadHistory(userId, sessionId);
    };

    const createNewConversation = () => {
        // Aquí puedes recargar la página o hacer alguna otra lógica para crear una nueva conversación
        window.location.reload(); // Recarga la página
    };

    const handleClassifyMessage = async (messageId, classification) => {
        await classifyMessage(messageId, classification, userId, sessionId, messages, setMessages);
    };

    const handlePresetMessage = (preset) => {
        setMessage(preset);
        // Opcional: si quieres que se envíe automáticamente
        // handleSendMessageWrapper();
    };
    
    
    const handleDateRangeSelect = (option) => {
        const today = new Date();
        let start, end;

        switch (option) {
            case 'thisWeek':
                start = new Date(today.setDate(today.getDate() - today.getDay() + 1));
                end = new Date(today.setDate(today.getDate() - today.getDay() + 7));
                setCurrentSelection('Semana');
                break;
            case 'thisMonth':
                start = new Date(today.getFullYear(), today.getMonth(), 1);
                end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                setCurrentSelection('Mes actual');
                break;
            case 'lastMonth':
                start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                end = new Date(today.getFullYear(), today.getMonth(), 0);
                setCurrentSelection('Mes anterior');
                break;
            default:
                return;
        }

        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(end.toISOString().split('T')[0]);
    };

    const handleManualDateChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
        setCurrentSelection(`${start} - ${end}`);
    };

    // Usar el hook para autoajustar el tamaño del textarea
    useAutoResizeTextArea();

    return (
        
        <div className="chat-simple-container">
            {/* Contenedor para el botón de alternar, posicionado arriba */}
            <div className="toggle-sidebar-container">
                <button
                    className="toggle-sidebar-button"
                    onClick={() => setSidebarOpen(prev => !prev)}
                    aria-label={sidebarOpen ? 'Cerrar barra lateral' : 'Abrir barra lateral'}
                >
                    ☰
                </button>
                <button
                    className="create-new-conversation-button"
                    onClick={createNewConversation}
                    aria-label="Nueva conversación"
                >
                    <i className="fas fa-comment-dots"></i>
                </button>
            </div>
    
            {/* Contenedor principal para la barra lateral y el área de chat */}
            <div className="chat-content">
                {/* Barra lateral */}
                <div className={`chat-sidebar ${sidebarOpen ? '' : 'closed'}`}>
                <h3>Conversaciones</h3>
                    {renderSessionList(sessions.today, "Hoy")}
                    {renderSessionList(sessions.last7Days, "Últimos 7 días")}
                    {renderSessionList(sessions.last30Days, "Últimos 30 días")}
                </div>
    
                {/* Área principal de chat */}
                <div className={`chat-main ${sidebarOpen ? 'with-sidebar' : 'no-sidebar'}`}>
                <div className="date-range-selector">
                    <button onClick={() => setDateRangeOpen(prev => !prev)} className="date-range-toggle">
                        {dateRangeOpen ? 'Ocultar' : currentSelection}
                    </button>
                    {dateRangeOpen && (
                        <div className="date-options">
                            <div className="date-quick-options">
                                <button onClick={() => handleDateRangeSelect('thisWeek')} className="date-option-button">
                                    Semana
                                </button>
                                <button onClick={() => handleDateRangeSelect('thisMonth')} className="date-option-button">
                                    Mes actual
                                </button>
                                <button onClick={() => handleDateRangeSelect('lastMonth')} className="date-option-button">
                                    Mes anterior
                                </button>
                            </div>
                            <div className="date-inputs">
                                <label>
                                    Inicio:
                                    <input
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => handleManualDateChange(e.target.value, endDate)}
                                    />
                                </label>
                                <label>
                                    Fin:
                                    <input
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => handleManualDateChange(startDate, e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                    )}
                </div>
                    <div className="chat-simple-window">
                        {messages.map((msg) => (
                            <div key={msg.id} className="message-container">
                                <div className={`message ${msg.sender}`}>
                                    {msg.text ? (
                                        msg.text.includes('|') && msg.text.includes('\n') ? (
                                            <div dangerouslySetInnerHTML={{ __html: convertTableTextToHtml(msg.text) }} />
                                        ) : (
                                            <ReactMarkdown>{msg.text}</ReactMarkdown>
                                        )
                                    ) : (
                                        msg.imageUrl && <img src={msg.imageUrl} alt="bot response" />
                                    )}
                                </div>
                                {msg.sender === 'bot' && msg.text && (
                                    <div className="message-actions">
                                    <button 
                                        className="copy-button"
                                        onClick={(e) => copyToClipboard(msg.text, e.currentTarget)}
                                        aria-label="Copy to clipboard"
                                    >
                                        <i className="fas fa-copy"></i>
                                    </button>
                                    <button 
                                        className={`vote-button upvote ${msg.classification === 'upvote' ? 'active' : ''}`}
                                        onClick={() => handleClassifyMessage(msg.id, 'upvote')}
                                        aria-label="Upvote"
                                    >
                                        <i className="fas fa-thumbs-up"></i>
                                    </button>
                                    <button 
                                        className={`vote-button downvote ${msg.classification === 'downvote' ? 'active' : ''}`}
                                        onClick={() => handleClassifyMessage(msg.id, 'downvote')}
                                        aria-label="Downvote"
                                    >
                                        <i className="fas fa-thumbs-down"></i>
                                    </button>
                                </div>
                    
                                )}
                            </div>
                        ))}
                        {/* Mostrar el indicador de carga */}
                        {isLoading && (
                            <div className="loading-indicator">
                                <i className="fas fa-spinner fa-spin"></i> {/* Icono de spinner */}
                                <span className="thinking-text">Chata está pensando<span className="dots"></span></span>
                            </div>
                        )}
                    
                    </div>
                    <PresetMessagesScrollable 
                        presetMessages={presetMessages}
                        onSelectMessage={handlePresetMessage}
                    />
                    <div className="chat-input">
                        <textarea
                            id="user-input-simple"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, handleSendMessageWrapper)}
                            placeholder="Type your message here..."
                            rows="1"
                        />
                        <button 
                            onClick={handleSendMessageWrapper} 
                            className="icon-button"
                            aria-label="Send"
                        >
                            <i className="fas fa-paper-plane"></i>
                        </button>

                        <button 
                            onClick={handleDownloadHistory} 
                            className="icon-button"
                            aria-label="Download History"
                        >
                            <i className="fas fa-download"></i>
                        </button>
                    </div>
                    <div className="footer-message">
                        ChaTa puede cometer errores. Comprueba la información importante.
                    </div>
                </div>
            </div>
        </div>
    );
};

